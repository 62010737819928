<template>
  <div class="">
    <TopBarTitleComponent title="Atributos" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <ButtonPrimaryComponent
            title="Nuevo atributo"
            @onSaveClick="onAddAttribute"
          />
        </div>
      </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4">
      <template v-if="apiResponse.success">
        <template v-if="items.length">
          <section class="input-filters">
            <InputSearchComponent @onChangeSearch="onChangeSearch" />
            <SelectOrderByComponent @onSelectOrderBy="onSelectOrderBy" />
          </section>
          <section class="table-items">
            <b-row class="table-items-header line-bottom">
              <b-col xs="12" sm="6" md="4">
                <h6 class="h6">Nombre</h6>
              </b-col>
              <b-col xs="12" sm="6" md="6">
                <h6 class="h6">Términos</h6>
              </b-col>
            </b-row>

            <paginate
              class="wrapper-pagination"
              name="resultQueryAttributes"
              :list="resultQueryAttributes"
              :per="perPaginate"
            >
              <div
                class="card table-items-body"
                v-for="(item, index) in paginated('resultQueryAttributes')"
                :key="index"
              >
                <div
                  class="card-body table-items-content"
                  @click="onEditAttribute(item)"
                >
                  <b-row class="">
                    <b-col xs="12" sm="6" md="4" class="table-col-item">
                      <span class="font-bold str-limit-space">{{
                        item.name
                      }}</span>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" class="table-col-item">
                      <span class="font-bold str-limit-space">{{
                        termsToStr(item)
                      }}</span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </paginate>
            <paginate-links
              v-show="resultQueryAttributes.length > perPaginate"
              for="resultQueryAttributes"
              :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
              :simple="{
                next: 'Siguiente »',
                prev: '« Regresar'
              }"
            ></paginate-links>
          </section>
        </template>
        <EmptyPage
          :media="emptyMedia3"
          text="No tienes atributos registrados."
          v-else
        />
      </template>
      <LoadingSkeletonTable v-else />
    </b-container>

    <el-dialog
      :visible.sync="dialogAttributesAdd"
      :before-close="onCloseVariablesAdd"
      append-to-body
    >
      <AttributesAdd />
    </el-dialog>

    <el-dialog
      :visible.sync="dialogAttributesEdit"
      :before-close="onCloseVariablesEdit"
      append-to-body
    >
      <AttributesEdit
        :setAttribute="attributeEdit"
        v-if="dialogAttributesEdit"
        @onDelete="onDeleteAttribute"
      />
    </el-dialog>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../../components/TopBarTitle";
import InputSearchComponent from "../../../components/InputSearch";
import SelectOrderByComponent from "../../../components/SelectOrderBy";
import LoadingSkeletonTable from "@/components/Animations/Loading/SkeletonTable";
import ButtonPrimaryComponent from "../../../components/ButtonPrimary";
import EmptyPage from "../../../components/EmptyPage";
import AttributesAdd from "./Add";
import AttributesEdit from "./Edit";

import catalogueService from "../../services/catalogueService";
import { emptyMedia3 } from "@/services/resources";

export default {
  name: "CategoryListComponent",
  data: () => ({
    dialogAttributesAdd: false,
    dialogAttributesEdit: false,
    attributeEdit: null,
    emptyMedia3,
    apiResponse: {
      success: false
    },
    searchQuery: null,
    orderByQuery: null,
    perPaginate: 10,
    paginate: ["resultQueryAttributes"],
    items: []
  }),
  computed: {
    resultQueryAttributes() {
      let attributes = this.items;
      return attributes
        .concat()
        .sort((a, b) => {
          switch (this.orderByQuery) {
            case "recent":
              return a.id < b.id ? 1 : -1;
            case "old":
              return a.id > b.id ? 1 : -1;
            case "a-z":
              return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
            case "z-a":
              return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
          }
        })
        .filter(item => {
          return this.searchQuery
            ? this.searchQuery
                .toLowerCase()
                .split(" ")
                .every(v => item.title.toLowerCase().includes(v))
            : item;
        });
    }
  },
  methods: {
    termsToStr({ terms }) {
      return terms.map(i => i.name).toString();
    },
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onSelectOrderBy({ orderBy }) {
      this.orderByQuery = orderBy;
    },
    onAddAttribute() {
      this.dialogAttributesAdd = true;
    },
    onEditAttribute(attribute) {
      this.attributeEdit = attribute;
      this.dialogAttributesEdit = true;
    },
    onCloseVariablesAdd() {
      this.dialogAttributesAdd = false;
    },
    onCloseVariablesEdit() {
      this.dialogAttributesEdit = false;
    },
    onDeleteAttribute() {
      this.onCloseVariablesEdit();
      this.getAttributes();
    },
    async getAttributes() {
      this.toggleApiResponse("success", false);

      try {
        let response = await catalogueService.getAttributes();
        const { success, data } = response.data;
        if (success) {
          this.items = data;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    TopBarTitleComponent,
    InputSearchComponent,
    SelectOrderByComponent,
    LoadingSkeletonTable,
    ButtonPrimaryComponent,
    EmptyPage,
    AttributesAdd,
    AttributesEdit
  },
  mounted() {
    this.getAttributes();
  }
};
</script>

<style scoped lang="scss">
.table-items {
  .table-items-body {
    .table-items-content {
      min-height: 76px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
