<template>
  <div class="">
    <el-form :model="attribute" :rules="rules" ref="form">
      <h3 class="h3 text-primary font-semi-bold mt-0 mb-4 pb-4">
        Detalle de Atributo
      </h3>
      <b-row>
        <b-col xs="12" sm="12" md="12">
          <el-form-item prop="name">
            <slot name="label">
              <label class="font-semi-bold m-0">Nombre</label>
            </slot>
            <el-input type="text" v-model="attribute.name"></el-input>
          </el-form-item>
          <el-form-item prop="terms">
            <slot name="label">
              <label class="font-semi-bold m-0">Términos</label>
            </slot>
            <el-select
              placeholder=""
              v-model="attributeTerms"
              multiple
              filterable
              allow-create
            >
              <el-option
                v-for="(terms, key) in attribute.terms"
                :key="key"
                :label="terms.name"
                :value="terms.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-center align-items-center mt-4">
        <ButtonDeleteComponent
          class="mr-4"
          @onConfirmDelete="onConfirmDelete"
        />
        <ButtonPrimaryComponent
          title="Agregar"
          @onSaveClick="handleSaveClick"
        />
      </div>
    </el-form>
  </div>
</template>

<script>
import ButtonPrimaryComponent from "../../../components/ButtonPrimary";
import Validate from "@/assets/validate";
import ButtonDeleteComponent from "../../../components/ButtonDelete";

import catalogueService from "../../services/catalogueService";

export default {
  name: "AttributedEdit",
  data() {
    return {
      apiResponse: {
        message: null
      },
      attributeTerms: [],
      attribute: this.setAttribute,
      rules: {
        name: [Validate.rules.required()]
        // terms: [Validate.rules.required()]
      }
    };
  },
  watch: {
    setAttribute(attribute) {
      this.attribute = attribute;
    },
    attribute(attribute) {
      this.attributeTerms = attribute.terms.map(({ name }) => name);
    }
  },
  computed: {
    formatTerms() {
      const terms = [];
      const termsNew = [];
      this.attributeTerms.forEach(t => {
        const findTerm = this.attribute.terms.find(term => term.name === t);
        if (findTerm) {
          terms.push(findTerm);
        } else {
          termsNew.push({
            name: t
          });
        }
      });

      return { terms, termsNew };
    }
  },
  methods: {
    async getAttributeId(attributeId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await catalogueService
          .getAttributeId(attributeId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, data: attribute } = response.data;
        if (success) {
          this.attribute = attribute;
        }
      } catch (error) {
        return false;
      }
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            const { terms, termsNew } = this.formatTerms;
            const data = {
              ...this.attribute,
              ...{ terms },
              ...{ terms_new: termsNew }
            };
            let response = await catalogueService
              .updateAttributeId(data)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message, data: attribute } = response.data;
            if (success) {
              this.$notifications.success({
                message
              });
              this.attribute = attribute;
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    async onConfirmDelete() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await catalogueService
          .deleteAttributeId(this.attribute.id)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
          this.$emit("onDelete");
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    ButtonPrimaryComponent,
    ButtonDeleteComponent
  },
  props: {
    setAttribute: {
      default: null
    }
  },
  mounted() {
    const { id: attributeId } = this.setAttribute;
    if (attributeId) this.getAttributeId(attributeId);
  }
};
</script>

<style></style>
