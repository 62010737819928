<template>
  <div class="">
    <el-form :model="form" :rules="rules" ref="form">
      <h3 class="h3 text-primary font-semi-bold mt-0 mb-4 pb-4">
        Detalle de Atributo
      </h3>
      <b-row>
        <b-col xs="12" sm="12" md="12">
          <el-form-item prop="name">
            <slot name="label">
              <label class="font-semi-bold m-0">Nombre</label>
            </slot>
            <el-input type="text" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item prop="terms">
            <slot name="label">
              <label class="font-semi-bold m-0">Términos</label>
            </slot>
            <el-select
              placeholder=""
              v-model="form.terms"
              multiple
              filterable
              allow-create
            ></el-select>
          </el-form-item>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-center align-items-center mt-4">
        <ButtonPrimaryComponent
          title="Agregar"
          @onSaveClick="handleSaveClick"
        />
      </div>
    </el-form>
  </div>
</template>

<script>
import ButtonPrimaryComponent from "../../../components/ButtonPrimary";
import Validate from "@/assets/validate";

import catalogueService from "../../services/catalogueService";

export default {
  name: "CategoryAddComponent",
  data: () => ({
    apiResponse: {
      message: null
    },
    form: {
      name: "",
      terms: []
    },
    rules: {
      name: [Validate.rules.required()],
      terms: [Validate.rules.required()]
    }
  }),
  computed: {
    terms() {
      return this.form.terms.map(t => ({
        name: t
      }));
    }
  },
  methods: {
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            const data = {
              ...this.form,
              ...{ terms: this.terms }
            };
            let response = await catalogueService
              .addAttributes(data)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message } = response.data;
            if (success) {
              this.$refs.form.resetFields();
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    }
  },
  components: {
    ButtonPrimaryComponent
  },
  props: {}
};
</script>

<style></style>
